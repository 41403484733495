@import "../../custom.scss";


.sidebar-dashboard {
  height: auto !important;
  min-height: 100vh;
  border: 0px !important;
}

.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-content {
  background-color: map-get($theme-colors, "primary")!important;
}

.pro-sidebar
  .pro-menu.shaped
  .pro-menu-item
  > .pro-inner-item
  > .pro-icon-wrapper {
  background-color: transparent;
}

.sidebar-menu-design {
  margin: 10px 10px !important;
  width: calc(100% - 20px) !important;
  padding: 0px !important;
}

.sidebar-dashboard li,
a {
  color:#fff !important;
}

.sidebar-menu-design-custom {
  padding: 2px 5px !important;
}
.sidebar-menu-design-custom.ps-active .ps-menu-button {
  background-color: map-get($theme-colors, "primary") !important;

  border-radius: 10px !important;
  color:#fff !important;
}

.sidebar-menu-design-custom:hover .ps-menu-button {
  background-color: map-get($theme-colors, "primary")!important;
  border-radius: 10px !important;
  color:#fff !important;
}

.sidebar-menu-design-custom:hover .ps-menu-icon {
  color:#fff !important;
  background-color:map-get($theme-colors, "secondary")!important;
}

.ps-menu-icon {
  border-radius: 8px !important;
}
.ps-menu-icon.ps-active {
  color:#fff !important;
  background-color: map-get($theme-colors, "secondary")!important;
}

.ps-menu-icon {
  background-color:#fff !important;
  color: #26282b !important;
}

.sidebar-dashboard-logo {
  text-align: right;
  margin-top: 8px;
  margin-bottom: 8px;
}
.sidebar-dashboard-logo button {
  color:#fff !important;
  background-color: transparent;
  border: 0;
}

.ps-menu-icon {
  color: map-get($theme-colors, "secondary")!important;
}
.custom-hr {
  color: #fff !important;
  border-image-source: linear-gradient(
    90deg,
    rgba(224, 225, 226, 0) 0%,
    #e0e1e2 49.52%,
    rgba(224, 225, 226, 0.15625) 99.04%
  );
  border-image-slice: 1;
  opacity: 1;
  width: 100%;
}

.custom-side-space {
  margin-left: 35px;
}
