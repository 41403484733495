body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

img {
  max-width: 100%;
  max-height: 100%;
}

@font-face {
  font-family: 'poppins-light';
  src: url('../src/assets/fonts/Poppins-Light.ttf');
}

@font-face {
  font-family: 'bahnschrift-regular';
  src: url('../src/assets/fonts/bahnschrift-regular.ttf');
}

.poppins-light {
  font-family: 'poppins-light';
}

body {
  font-family: 'bahnschrift-regular';
}

.bahnschrift-regular {
  font-family: 'bahnschrift-regular';
}

.login-main-div {
  height: 100vh;
  position: relative !important;
}

.login-con {
  align-items: center;
  height: 100vh;
  width: 600px;
  margin: 0 auto !important;
}

.login-form input {
  border-radius: 0px;
  font-family: 'poppins-light';
  font-size: 12px;
}

.btn-login {
  background: linear-gradient(to right, #0054e8, #15fefe);
  border-radius: 0px;
  color: #fff;
  padding: 10px 60px;
}

/* top-navbar */




.avatar-image-box-dropdown {
  display: flex;
  align-items: center;
}

.avatar-image {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  object-fit: cover;
}

.user-name {
  margin-left: 10px;
}

.user-membership {
  text-decoration: none;
  color: #00d9fa;
}

.user-membership:hover {
  border-bottom: #00d9fa 1px solid;
  color: #00d9fa;
}

/* main layout */

.main-layout {
  display: flex !important;
}

.main-content {
  width: 100%;
}



.close-icon {
  color: #adadad;
}


.table-main-header {
  justify-content: space-between;
  display: flex;
  align-items: center;
  background-color: #02275e;
}

.card-header-customs {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.no-btn-structure {
  background-color: transparent;
  border: none;
  font-size: 18px;
}

tr,
th,
td {
  font-size: 14px;
  vertical-align: middle;
}

.switch {
  width: 100px !important;
}

.pagination-custom {
  list-style-type: none;
  display: flex;
  gap: 10px;
  justify-content: center;
}

.pagination-custom a {
  text-decoration: none;
  color: #02275e;
}

.w-80-px {
  width: 100px;
}

.avatar-custom {
  width: 40px;
  height: 40px;
  object-fit: cover;
  object-position: top;
  border-radius: 50%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  min-width: 50px;
}

.font-18 {
  font-size: 18px;
}

.pagination-custom li {
  padding: 2px 10px;
  border: 1px solid #02275e;
}

.delete-card {
  width: 250px;
}

.btn-delete {
  padding: 2px 10px;
  border: none;
}

.delete-div {
  display: flex;
}

.modal {
  font-size: 12px;
}

.show-hide-btn {
  position: absolute;
  top: 25px;
  right: 5px;
}

.no-image-custom {
  width: 100%;
  height: 200px;
  object-fit: cover;
  object-position: center;
  margin-bottom: 30px;
}

.user-img-table {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50px;
}

.user-details-img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50px;
  border: 2px solid #02275e;
  position: relative;
  bottom: 40px;
  left: -1px;
}

.user-details {
  margin-top: -25px !important;
}

.show-992 {
  display: none;
}

.services-para {
  display: inline-block !important;
  width: 100px;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  font-size: 14px;
  margin-bottom: -6px;
  border-radius: 10px;
}

.inner-slide {
  height: 300px;
  width: 100%;
}

.inner-slide img {
  height: 300px;
  width: 100%;
  object-fit: cover !important;
  object-position: center;
}

.inner-slide video {
  height: 300px;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.close-btn {
  background: #02275e;
  width: 20px;
  height: 20px;
  padding: 9px;
  position: absolute;
  right: 0;
  z-index: 99;
  border: 0px;
  right: 4px;
  top: 4px;
}

.close-icon {
  color: #fff !important;
  position: relative;
  top: -10px;
  left: -5px;
}

.description-tooltip .tooltip-inner {
  max-width: 250px;
  position: relative;
  padding: 12px 10px;
  font-size: 12px;
  background-color: #02275e;
}

.custom-modal-header .btn-close {
  background-image: url('assets/images/close-btn.png') !important;
}

.error-container {
  position: relative;
  height: calc(100vh - 3rem);
  display: flex;
  align-items: center;
  justify-content: center;
}

.error-404 {
  width: 280px;
}



.cat-icon {
  font-size: 25px;
}

.toast-updated {
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.global-loader {
  position: fixed;
  top: 50%;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
}

.global-loader div {
  justify-content: center;
}

.global-loader-inner {
  justify-content: center;
}

.pagination-custom .selected {
  background-color: #02275e;
  color: #fff !important;
}

.card-inner {
  display: flex;
  position: relative;
  align-items: center;
  border-radius: 5px;
  padding: 25px 25px;
  margin-bottom: 25px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background: linear-gradient(245deg, rgb(0 84 232) 48%, rgb(2 39 94) 10%);
}

.user-img {
  width: 50px;
  height: 50px;
  background-color: #fff;
  border-radius: 50px;
  object-fit: cover;
  padding: 2px;
}

.font-12 {
  font-size: 12px;
}

/* Users Search */

.users-search {
  border-radius: 0px !important;
  width: 320px !important;
}

.user-search {
  display: flex;
}

.img-td {
  width: 80px;
}

/* Users Search */

@media (max-width: 992px) {


  .show-992 {
    display: block;
  }
}

@media (max-width: 600px) {
  .login-con-1 {
    width: 100%;
  }
}

.wish-tags-all {
  font-size: 12px;
  margin-bottom: 12px;
  padding: 5px 5px;
  border-radius: 4px;
  display: inline-block;
  margin-right: 10px;
}
.pro-item-content button:hover {
  background-color: #0054e8 !important;
}
.top-latest-users-table {
  padding: 20px 20px;
}
.symbol {
  display: inline-block;
  flex-shrink: 0;
  position: relative;
  border-radius: 0.475rem;
}
.symbol.symbol-45px > img {
  width: 45px;
  height: 45px;
  flex-shrink: 0;
  display: inline-block;
  border-radius: 0.475rem;
}

.h-6px {
  height: 6px !important;
}
.top-latest-users-table tr {
  border: 0px !important;
  border-bottom: 1px dashed #e1e3ea !important;
  border-top: 1px dashed #e1e3ea !important;
}
.top-latest-users-table td {
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: transparent;
  border: 0px !important;
  border-top: 1px dashed #e1e3ea !important;
  border-bottom: 1px dashed #e1e3ea !important;
}
.top-latest-users-table h4 {
  margin-bottom: 5px;
}
.top-latest-users-table h6 {
  color: #a1a5b7;
  font-weight: 400;
  font-size: 14px;
}
